/* styles for rendering quilljs compiled HTML */
/* IMPORTANT: these custom css rules should be in sync with the custom css for the WYSIWYG's in the admin portal */

.quill {
  line-height: 23px !important;
  font-size: 16px !important;
  font-family: "Graphik Web" !important;
  font-feature-settings: "liga", "kern" !important;
}

.quill p,
.quill ol,
.quill ul,
.quill pre,
.quill blockquote,
.quill h1,
.quill h2,
.quill h3,
.quill h4,
.quill h5,
.quill h6 {
  margin: 0 !important;
  padding: 0 !important;
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
}
.quill p:not(:last-child),
.quill h1:not(:last-child),
.quill h2:not(:last-child),
.quill h3:not(:last-child) {
  margin-bottom: 16px !important;
}
.quill h1:first-child,
.quill h2:first-child,
.quill h3:first-child {
  margin-top: 0 !important;
}
.quill strong {
  font-weight: 600 !important;
}

p:has(br) {
  line-height: 0 !important;
}

/* h1 */
.quill h1 {
  font-weight: 600 !important;
  font-size: 32px !important;
  letter-spacing: 0.4px !important;
  line-height: 40px !important;
}
.quill h1.ql-size-small {
  font-size: 24px !important;
  font-weight: 600 !important;
  letter-spacing: 0.3px !important;
  line-height: 30px !important;
}
.quill h1.ql-size-large {
  font-size: 40px !important;
  font-weight: 600 !important;
  letter-spacing: 0.5px !important;
  line-height: 50px !important;
}
.quill h1.ql-size-huge {
  font-size: 56px !important;
  font-weight: 600 !important;
  letter-spacing: 0.7px !important;
  line-height: 70px !important;
}

/* h2 */
.quill h2 {
  font-size: 24px !important;
  font-weight: 600 !important;
  letter-spacing: 0.3px !important;
  line-height: 30px !important;
}
.quill h2.ql-size-small {
  font-size: 20px !important;
  font-weight: 600 !important;
  letter-spacing: 0.25px !important;
  line-height: 26px !important;
}
.quill h2.ql-size-large {
  font-size: 32px !important;
  font-weight: 600 !important;
  letter-spacing: 0.4px !important;
  line-height: 40px !important;
}
.quill h2.ql-size-huge {
  font-size: 48px !important;
  font-weight: 600 !important;
  letter-spacing: 0.6px !important;
  line-height: 62px !important;
}

/* h3 */
.quill h3 {
  font-size: 20px !important;
  font-weight: 600 !important;
  letter-spacing: 0.25px !important;
  line-height: 26px !important;
}
.quill h3.ql-size-small {
  font-size: 16px !important;
  font-weight: 600 !important;
  letter-spacing: 0.2px !important;
  line-height: 23px !important;
}
.quill h3.ql-size-large {
  font-size: 24px !important;
  font-weight: 600 !important;
  letter-spacing: 0.3px !important;
  line-height: 30px !important;
}
.quill h3.ql-size-huge {
  font-size: 40px !important;
  font-weight: 600 !important;
  letter-spacing: 0.5px !important;
  line-height: 50px !important;
}

/* regular */
.quill .ql-size-small {
  font-size: 14px !important;
  letter-spacing: 0 !important;
  line-height: 21px !important;
}
.quill .ql-size-large {
  font-size: 20px !important;
  letter-spacing: 0 !important;
  line-height: 26px !important;
}
.quill .ql-size-huge {
  font-size: 32px !important;
  letter-spacing: 0 !important;
  line-height: 40px !important;
}

@media only screen and (max-width: 960px) {
  /* h1 */
  .quill h1 {
    font-size: 24px !important;
    font-weight: 600 !important;
    letter-spacing: 0.3px !important;
    line-height: 30px !important;
  }
  .quill h1.ql-size-small {
    font-size: 20px !important;
    font-weight: 600 !important;
    letter-spacing: 0.25px !important;
    line-height: 26px !important;
  }
  .quill h1.ql-size-large {
    font-size: 32px !important;
    font-weight: 600 !important;
    letter-spacing: 0.4px !important;
    line-height: 40px !important;
  }
  .quill h1.ql-size-huge {
    font-size: 40px !important;
    font-weight: 600 !important;
    letter-spacing: 0.5px !important;
    line-height: 50px !important;
  }

  /* h2 */
  .quill h2 {
    font-size: 20px !important;
    font-weight: 600 !important;
    letter-spacing: 0.25px !important;
    line-height: 26px !important;
  }
  .quill h2.ql-size-small {
    font-size: 16px !important;
    font-weight: 600 !important;
    letter-spacing: 0.2px !important;
    line-height: 23px !important;
  }
  .quill h2.ql-size-large {
    font-size: 24px !important;
    font-weight: 600 !important;
    letter-spacing: 0.3px !important;
    line-height: 30px !important;
  }
  .quill h2.ql-size-huge {
    font-size: 32px !important;
    font-weight: 600 !important;
    letter-spacing: 0.4px !important;
    line-height: 40px !important;
  }

  /* h3 */
  .quill h3 {
    font-size: 16px !important;
    font-weight: 600 !important;
    letter-spacing: 0.2px !important;
    line-height: 23px !important;
  }
  .quill h3.ql-size-small {
    font-size: 14px !important;
    font-weight: 600 !important;
    letter-spacing: 0.17px !important;
    line-height: 21px !important;
  }
  .quill h3.ql-size-large {
    font-size: 20px !important;
    font-weight: 600 !important;
    letter-spacing: 0.25px !important;
    line-height: 26px !important;
  }
  .quill h3.ql-size-huge {
    font-size: 24px !important;
    font-weight: 600 !important;
    letter-spacing: 0.3px !important;
    line-height: 30px !important;
  }

  /* regular */
  .quill .ql-size-small {
    font-size: 12px !important;
    letter-spacing: 0 !important;
    line-height: 19px !important;
  }
  .quill .ql-size-large {
    font-size: 20px !important;
    letter-spacing: 0 !important;
    line-height: 23px !important;
  }
  .quill .ql-size-huge {
    font-size: 24px !important;
    letter-spacing: 0 !important;
    line-height: 30px !important;
  }
}

.quill .ql-bg-black {
  background-color: #000 !important;
}
.quill .ql-bg-red {
  background-color: #e60000 !important;
}
.quill .ql-bg-orange {
  background-color: #f90 !important;
}
.quill .ql-bg-yellow {
  background-color: #ff0 !important;
}
.quill .ql-bg-green {
  background-color: #008a00 !important;
}
.quill .ql-bg-blue {
  background-color: #06c !important;
}
.quill .ql-bg-purple {
  background-color: #93f !important;
}
.quill .ql-color-white {
  color: #fff !important;
}
.quill .ql-color-red {
  color: #e60000 !important;
}
.quill .ql-color-orange {
  color: #f90 !important;
}
.quill .ql-color-yellow {
  color: #ff0 !important;
}
.quill .ql-color-green {
  color: #008a00 !important;
}
.quill .ql-color-blue {
  color: #06c !important;
}
.quill .ql-color-purple {
  color: #93f !important;
}
.quill .ql-direction-rtl {
  direction: rtl !important;
  text-align: inherit !important;
}
.quill .ql-align-center {
  text-align: center !important;
}
.quill .ql-align-justify {
  text-align: justify !important;
}
.quill .ql-align-right {
  text-align: right !important;
}
.quill a {
  color: #222222 !important;
}

/* list styles */
.quill ol,
.quill ul {
  padding-left: 2px !important;
}
.quill ol > li,
.quill ul > li {
  list-style-type: none !important;
}
.quill ul > li::before {
  content: "\2022" !important;
}
.quill li::before {
  width: 1.2em !important;
  display: inline-block !important;
  white-space: nowrap !important;
}
.quill li:not(.ql-direction-rtl)::before {
  text-align: right !important;
  margin-left: -1.5em !important;
  margin-right: 0.3em !important;
}
.quill li.ql-direction-rtl::before {
  margin-left: 0.3em !important;
  margin-right: -1.5em !important;
}
.quill ol li:not(.ql-direction-rtl),
.quill ul li:not(.ql-direction-rtl) {
  padding-left: 1.5em !important;
}
.quill ol li.ql-direction-rtl,
.quill ul li.ql-direction-rtl {
  padding-right: 1.5em !important;
}
