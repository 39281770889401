/*
        This font software is the property of Commercial Type.

        You may not modify the font software, use it on another website, or install it on a computer.

        License information is available at http://commercialtype.com/eula
        For more information please visit Commercial Type at http://commercialtype.com or email us at info[at]commercialtype.com

        Copyright (C) 2023 Schwartzco Inc.
        License: 2305-LDPITE     
*/

@font-face {
  font-family: "Graphik Web";
  src: url("Graphik-BoldItalic-Web.woff2") format("woff2"),
    url("Graphik-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

.Graphik-BoldItalic-Web {
  font-family: "Graphik Web";
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Web";
  src: url("Graphik-Bold-Web.woff2") format("woff2"),
    url("Graphik-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

.Graphik-Bold-Web {
  font-family: "Graphik Web";
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Web";
  src: url("Graphik-Semibold-Web.woff2") format("woff2"),
    url("Graphik-Semibold-Web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

.Graphik-Semibold-Web {
  font-family: "Graphik Web";
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Web";
  src: url("Graphik-Medium-Web.woff2") format("woff2"),
    url("Graphik-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

.Graphik-Medium-Web {
  font-family: "Graphik Web";
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Web";
  src: url("Graphik-RegularItalic-Web.woff2") format("woff2"),
    url("Graphik-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

.Graphik-RegularItalic-Web {
  font-family: "Graphik Web";
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Web";
  src: url("Graphik-Regular-Web.woff2") format("woff2"),
    url("Graphik-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

.Graphik-Regular-Web {
  font-family: "Graphik Web";
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}
