html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  font-family: "Graphik Web", sans-serif, "Lato", "Roboto", "Helvetica", "Arial";
  font-feature-settings: "liga", "kern";
  color: #222222;
  background-color: #ffffff;
  line-height: 1;
  font-weight: 400;
  letter-spacing: 0.01071em;
  font-size: 0.875rem;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
  border: 0;
}

strong,
b {
  font-weight: 700;
}

.bold {
  font-weight: 700;
}

p,
ol,
ul,
pre,
blockquote,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: unset;
  font-size: unset;
  line-height: unset;
}

@media print {
  body {
    background-color: #ffffff;
  }
}
body::backdrop {
  background-color: #ffffff;
}
